<template>
  <base-layout
    show-back-btn="true"
    page-default-back-link="/"
    show-info="false"
  >
    <ion-text
      color="primary"
      class="ion-text-center title-headline"
    >
      <h1>Kontrolpanel</h1>
    </ion-text>
    <ion-grid>
      <ion-row>
        <ion-col
          size="6"
          v-for="button in buttons"
          :key="button.name"
          @click="activate(button.name)"
        >
          <ion-item
            :color="button.active ? 'primary' : 'secondary'"
            button="true"
            detail="true"
          >
            <ion-label>{{ button.name }}</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="control-content">
      <transition name="fade">
        <div v-if="buttons[0].active">
          <dawa-upload></dawa-upload>
        </div>
        <div v-else-if="buttons[1].active">
          <person-upload></person-upload>
        </div>
        <div v-else-if="buttons[2].active">
          <work-plan-upload></work-plan-upload>
        </div>
        <div v-else-if="buttons[3].active">
          <info-upload></info-upload>
        </div>
        <div v-else-if="buttons[4].active">
          <login-upload></login-upload>
        </div>
      </transition>
    </div>
  </base-layout>
</template>

<script>
import DawaUpload from "../components/panel/DawaUpload";
import PersonUpload from "../components/panel/PersonUpload";
import InfoUpload from "../components/panel/InfoUpload";
import WorkPlanUpload from "../components/panel/WorkPlanUpload";
import LoginUpload from "../components/panel/LoginUpload";

import { mapGetters } from "vuex";
import {
  IonButton,
  IonLabel,
  IonInput,
  IonText,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
} from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonText,
    IonLabel,
    IonInput,
    IonTextarea,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    DawaUpload,
    PersonUpload,
    InfoUpload,
    WorkPlanUpload,
    LoginUpload,
  },
  data() {
    return {
      buttons: [
        {
          name: "Dawa",
          active: true,
        },
        {
          name: "Teknikere",
          active: false,
        },
        {
          name: "Vagtskema",
          active: false,
        },
        {
          name: "Infoside",
          active: false,
        },
        {
          name: "Login data",
          active: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["containerName", "infoTitel", "infoContent"]),
  },
  methods: {
    activate(name) {
      for (let i = 0; i < this.buttons.length; i++) {
        const element = this.buttons[i];

        element.active = false;

        if (name == element.name) {
          element.active = true;
        }
      }
    },
  },
};
</script>
