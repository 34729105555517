<template>
  <div>
    <ion-label>Informationsside indhold</ion-label>
    <ion-item>
      <ion-label position="floating">Titel</ion-label>
      <ion-input
        type="text"
        v-model="titel"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="floating">Indhold</ion-label>
      <ion-textarea
        v-model="content"
        rows="8"
      ></ion-textarea>
    </ion-item>
    <ion-button
      expand="block"
      @click="saveInfo()"
    >Gem indhold</ion-button>
    <br/>
    <ion-label color="primary" v-if="saveText.length > 0">{{ saveText }}</ion-label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  IonButton,
  IonLabel,
  IonInput,
  IonTextarea,
  IonItem,
} from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonLabel,
    IonInput,
    IonTextarea,
    IonItem,
  },
  data() {
    return {
      titel: "",
      content: "",
      saveText: "",
    };
  },
  mounted() {
    this.titel = this.infoTitel;
    this.content = this.infoContent;
  },
  computed: {
    ...mapGetters(["infoTitel", "infoContent"]),
  },
  methods: {
    saveInfo() {
      var app = this;
      
      var result = this.$store.dispatch("saveInfoContent", {
        titel: this.titel,
        content: this.content,
      });

      result.then(function (result) {
        app.saveText = result;
      });
    },
  },
};
</script>
