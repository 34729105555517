<template>
  <div>
    <h2>Upload tekniker-data</h2>
    <ion-label>Vil du opdatere teknikere-data?</ion-label>
    <br />
    <input
      type="file"
      id="fileInput"
      ref="file"
      accept=".csv"
      @change="fileReader()"
    />
    <hr />
    {{ data.length }} / {{ csvArray.length }} (Fejl: {{ errors.length }})

    <hr />
    <div v-if="fileDone">
      <ion-button expand="block" @click="savePersons()">Gem data</ion-button>
    </div>
    <br />
    <ion-label color="primary" v-if="personMessage.length > 0">{{
      personMessage
    }}</ion-label>
    <div v-if="errors.length > 0 && fileDone">
      <h2>Fejl</h2>
      <ion-label>Fejl bliver ikke gemt i data!</ion-label>
      <ul style="padding: 0; list-style:none;">
        <li v-for="error in errors" :key="error.Initialer">
          <b>{{ error.Initialer }}</b> - {{ error.Adresse }},
          {{ error.Postnummer }} {{ error.By }}<br />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";

import { mapGetters } from "vuex";
import { IonButton, IonLabel } from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonLabel,
  },
  data() {
    return {
      fileDone: false,
      fileName: "",
      csvArray: [],
      data: [],
      errors: [],
      personMessage: "",
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    savePersons() {
      var app = this;
      this.$store.commit("saveData", this.data);

      var result = this.$store.dispatch("saveToBlobStorage", {
        data: this.data,
        blobName: "persons",
      });

      result.then(function(data) {
        app.personMessage = data;
      });
    },
    fileReader() {
      var app = this;
      this.message = "Loading";
      this.loading = true;
      var oFile = this.$refs.file.files[0];

      //reset
      this.data = [];
      this.csvArray = [];
      this.errors = [];

      Papa.parse(oFile, {
        header: true,
        download: true,
        dynamicTyping: true,
        encoding: "UTF-8",
        complete: function(results) {
          app.csvArray = results.data;

          for (let i = 0; i < app.csvArray.length; i++) {
            setTimeout(function() {
              const element = app.csvArray[i];
              app
                .addGeoData(5, element)
                .then((data) => {
                  if (data != null) {
                    app.data.push(data);
                  } else {
                    app.errors.push(element);
                  }

                  if (i + 1 == app.csvArray.length) {
                    app.fileDone = true;
                  }
                })
                .catch(function(err) {
                  console.log("for", err);
                });
            }, 100);
          }
        },
      });
    },
    async addGeoData(retries, item, backoff = 1000) {
      var app = this;

      return await axios
        .get(
          "https://dawa.aws.dk/adresser?q=" +
            item.Adresse +
            " " +
            item.Postnummer +
            " " +
            item.By +
            "&struktur=mini"
        )
        .then((response) => {
          var data = response.data;
          if (data.length > 0) {
            item.x = data[0].x;
            item.y = data[0].y;

            return item;
          }
          return null;
        })
        .catch(function(error) {
          if (retries > 0) {
            setTimeout(() => {
              console.log("Retry", backoff);
              app.addGeoData(--retries, item, backoff * 2);
            }, backoff);
          } else {
            // no retries left, calling callback with error
            console.log("LIMIT");
            return "error";
          }
        });
    },
  },
};
</script>
