<template>
  <div>
    <h2>Hent postnumre fra DAWA</h2>
    <ion-label>Vil du hente/opdatere DAWA postnumre?</ion-label>
    <ion-button
      expand="block"
      @click="getDawaDataAndSave()"
    >Hent postnumre</ion-button>
    <br>
    <ion-label
      color="primary"
      v-if="dawaMessage.length > 0"
    >{{ dawaMessage }}</ion-label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IonButton, IonLabel } from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonLabel,
  },
  data() {
    return {
      dawaMessage: "",
    };
  },
  computed: {
    ...mapGetters(["containerName"]),
  },
  methods: {
    getDawaDataAndSave() {
      var app = this;

      var result = this.$store.dispatch("getDawaDataAndSave");

      result.then(function (data) {
        app.dawaMessage = data;
      });
    },
  },
};
</script>
